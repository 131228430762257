import React, { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, IconButton, Link, Skeleton, Stack } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'

import { QueryKeys } from '../../../helpers/queryKeys'
import { postService } from '../../../services/PostService'
import { IReactionResponse } from '../../../services/PostService/types'
import * as S from '../styles'
import ReactionsInfoItem from './ReactionsInfoItem'

type ReactionsInfoDialogPropsType = {
  postId: string;
  showDialog: boolean;
  onClose: () => void;
  t: TFunction
}

const ReactionsInfoDialog = ({ postId, showDialog, onClose, t }: ReactionsInfoDialogPropsType) => {
  const query = useInfiniteQuery(
    [QueryKeys.Reactions, postId],
    {
      queryFn: ({ pageParam = 1 }) => postService.getReactions({
        postId,
        page: pageParam,
        limit: 20,
        orders: Array(JSON.stringify(['created_at', 'asc']))
      }),
      getNextPageParam: ({ metadata }) => {
        const nextPage = metadata.page + 1
        return nextPage <= metadata.pages ? nextPage : undefined
      },
      refetchOnWindowFocus: false,
      retry: 0
    }
  )

  const items = useMemo(() => {
    return query.data
      ? query.data.pages.reduce<IReactionResponse[]>((prev, curr) => {
        return [...prev, ...curr.result]
      }, [])
      : []
  }, [query.data])

  const renderContent = () => {
    if (!items) {
      return <div>{t('REACTION_MODAL.NO_REACTIONS')}</div>
    }

    if (query.isError) {
      return <div>{t('REACTION_MODAL.FAIL_FIND_REACTIONS')}</div>
    }

    if (query.isLoading) {
      return (
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton variant="circular" height={40} width={40} />
            <Skeleton variant="rectangular" height={24} width="60%" />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton variant="circular" height={40} width={40} />
            <Skeleton variant="rectangular" height={24} width="80%" />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton variant="circular" height={40} width={40} />
            <Skeleton variant="rectangular" height={24} width="45%" />
          </Stack>
        </Stack>
      )
    }

    return (
      <S.ContainerInfiniteScroll>
        <InfiniteScroll
          dataLength={items.length}
          next={query.fetchNextPage}
          hasMore={Boolean(query.hasNextPage)}
          style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%' }}
          loader={<Link
            variant="body2"
            fontWeight='fontWeightBold'
            color='secondary'
          >
            {t('REACTION_MODAL.LOADING')}
          </Link>}
        >
          {items.map(item => (
            <ReactionsInfoItem key={item.id} item={item}/>
          ))}
        </InfiniteScroll>
      </S.ContainerInfiniteScroll>
    )
  }

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      fullWidth
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="close"
          onClick={onClose} style={{ marginTop: 12, marginRight: 8 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        {renderContent()}
      </DialogContent>
    </Dialog>
  )
}

export default ReactionsInfoDialog
