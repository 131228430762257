import React from 'react'

import { CardContent, CardHeader, Grid, IconButton, Skeleton as MatSkeleton } from '@mui/material'

import * as S from './styles'
import { SkeletonProps } from './types'

const Skeleton: React.FC<SkeletonProps> = ({ index = 0 }) => {
  return (
    <>
      <S.ContainerCardSkeleton key={index}>
        <CardHeader
          avatar={<MatSkeleton variant="circular" width={40} height={40} />}
          title={<MatSkeleton variant="rectangular" width={80} height={20}/>}
          subheader={<MatSkeleton variant="rectangular" width={40} height={12} />}
        />
        <CardContent>
          <MatSkeleton variant="rectangular" width='100%' height={60} />
        </CardContent>
        <S.ContainerCardActions>
          <Grid display='flex' alignItems='center'>
            <IconButton aria-label="like">
              <MatSkeleton variant="rectangular" width={20} height={20} />
            </IconButton>
            <MatSkeleton variant="rectangular" width={40} height={20} />
          </Grid>
          <MatSkeleton variant="rectangular" width='30%' height={16} />
        </S.ContainerCardActions>
      </S.ContainerCardSkeleton>
    </>
  )
}

export default Skeleton
