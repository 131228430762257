import { Storage } from '..'

export const localStorageWeb: Storage = {
  getItem: key => {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return null
  },
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  removeItem: key => {
    localStorage.removeItem(key)
  }
}
