import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactQuill from 'react-quill'

import { SendOutlined } from '@mui/icons-material'
import { CircularProgress, IconButton, Link } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'

import { QueryKeys } from '../../../helpers/queryKeys'
import { employeeService } from '../../../services/EmployeeService'
import { postService } from '../../../services/PostService'
import { ICommentAnswer } from '../../../services/PostService/types'
import { capitalizeText } from '../../../utils/global'
import ToastNotification from '../../ToastNotification'
import * as S from '../styles'
import { ContainerButtonQuillAnswers, QuillContainer } from '../styles'
import CommentAnswersSkeleton from './CommentAnswersSkeleton'
import PostCommentsAnswersItem from './PostCommentsAnswerItem'

type PostCommentsAnswersPropsType = {
  t: TFunction;
  commentId: string;
  commentAuthor: string;
  showInput: boolean;
  showAllAnswers: boolean;
  onSuccess: () => void;
}

const PostCommentsAnswers = ({
  t,
  commentId,
  commentAuthor,
  showInput,
  showAllAnswers,
  onSuccess
}: PostCommentsAnswersPropsType) => {
  const [html, setHtml] = useState('')
  const [isHtmlEmpty, setIsHtmlEmpty] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  const query = useInfiniteQuery(
    [QueryKeys.CommentAnswers, commentId],
    {
      queryFn: ({ pageParam = 1 }) => postService.getCommentsAnswers({
        commentId,
        page: pageParam,
        limit: showAllAnswers ? 1 : 30,
        orders: Array(JSON.stringify(['created_at', 'asc']))
      }),
      getNextPageParam: ({ metadata }) => {
        const nextPage = metadata.page + 1
        return nextPage <= metadata.pages ? nextPage : undefined
      },
      refetchOnWindowFocus: false,
      retry: 0
    }
  )

  const items = useMemo(() => {
    return query.data
      ? query.data.pages.reduce<ICommentAnswer[]>((prev, curr) => {
        return [...prev, ...curr.result]
      }, [])
      : []
  }, [query.data])

  const refreshList = () => {
    return query.refetch()
  }

  const sendCommentAnswer = useCallback(async () => {
    try {
      setLoading(true)
      await postService.createCommentAnswer({
        commentId,
        comment_answer: html
      })

      setHtml('')
      refreshList()
      onSuccess()
    } catch (error) {
      ToastNotification({
        type: 'error',
        id: 'createCommentAnswer',
        className: 'toast-error',
        content: t('COMMENT_ANSWER.FAIL_CREATE_COMMENT_ANSWER')
      })
    } finally {
      setLoading(false)

      // setHtml('')
      refreshList()
      onSuccess()
    }
  }, [t, html, setHtml])

  const handleMention = useCallback(async (searchTerm: string, renderList: any) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current?.abort()
      }

      abortControllerRef.current = new AbortController()

      if (searchTerm.toUpperCase().includes('TODOS')) {
        return renderList(
          [{
            id: 'ALL',
            value: t('MENTION.MENTION_ALL'),
            email: 'ALL'
          }],
          searchTerm
        )
      }

      const employeesData = await employeeService.getEmployees(
        searchTerm,
        abortControllerRef.current?.signal
      )

      const mappedEmployeeData = employeesData.content.map(employee => ({
        id: employee.id,
        value: capitalizeText(employee.name),
        email: employee.email
      }))

      renderList(mappedEmployeeData, searchTerm)
    } catch (ex) {
      console.warn(ex)
      renderList([], searchTerm)
    }
  }, [t])

  const modules = useMemo(() => ({
    toolbar: false,
    clipboard: {
      matchVisual: true
    },
    mention: {
      allowedChars: /^[A-Za-z\sÁÃáãÓÕóõÉÊéê]*$/,
      mentionDenotationChars: ['@'],
      dataAttributes: ['email'],
      minChars: 3,
      defaultMenuOrientation: 'top',
      source: handleMention,
      renderLoading: () => t('LIST_POST.LOADING'),
      /*
        As duas propriedades abaixo são necessárias
        para quando o usuário digitar um email não habilitar a busca da menção
      */
      isolateCharacter: true,
      allowInlineMentionChar: true
    }
  }), [])

  useEffect(() => {
    if (!showInput) {
      setHtml(`@${commentAuthor}`)
    } else {
      setHtml('')
    }
  }, [])

  const validateHtml = (editor: ReactQuill.UnprivilegedEditor) => {
    if ((editor.getContents().ops || []).length !== 1) setIsHtmlEmpty(false)
    else setIsHtmlEmpty(editor.getText().trim().length === 0)
  }

  const handleChangeHtml = (value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
    setHtml(value)
    validateHtml(editor)
  }

  const renderAnswers = () => {
    if (query.isError) {
      return <div style={{ margin: 16, textAlign: 'center' }}>{t('COMMENT_ANSWER.FAIL_FIND_ANSWERS')}</div>
    }

    if (query.isLoading) {
      return (<CommentAnswersSkeleton />)
    }

    if (!items.length) {
      return <div style={{ display: 'flex', flex: 1 }} />
    }

    return (
      <S.ContainerCommentsAnswers>
        <InfiniteScroll
          dataLength={items.length}
          next={query.fetchNextPage}
          hasMore={Boolean(query.hasNextPage)}
          style={{ display: 'flex', flexDirection: 'column' }}
          loader={<Link
            variant="body2"
            fontWeight='fontWeightBold'
            color='secondary'
          >
            {t('VIEW.LOADING')}
          </Link>}
        >
          {items.map(answer => {
            return (
              <PostCommentsAnswersItem
                t={t}
                key={answer.id}
                answer={answer}
              />
            )
          })}
        </InfiniteScroll>
      </S.ContainerCommentsAnswers>
    )
  }

  const renderInput = () => {
    if (!showInput) {
      return null
    }

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    return (
      <ContainerButtonQuillAnswers isFocused={isFocused}>
        <div style={{ width: '100%' }}>
          <div style={{ maxWidth: '270px', flex: 1 }}>
            <QuillContainer>
              <ReactQuill
                theme="snow"
                value={html}
                onChange={handleChangeHtml}
                onFocus={handleFocus}
                onBlur={handleBlur}
                modules={modules}
                placeholder={t('COMMENT_ANSWER.POST_A_ANSWER')}
              />
            </QuillContainer>
          </div>
        </div>
        <IconButton
          disabled={isHtmlEmpty}
          disableRipple
          onClick={sendCommentAnswer}
        >
          {(loading)
            ? (<CircularProgress size={24} color='primary'/>)
            : (<SendOutlined fontSize="medium" color={isHtmlEmpty ? 'disabled' : 'primary'} />)}
        </IconButton>
      </ContainerButtonQuillAnswers>
    )
  }

  return (
    <div>
      {renderInput()}
      {renderAnswers()}
    </div>
  )
}

export default PostCommentsAnswers
