import React from 'react'
import ReactQuill from 'react-quill'

import { Button, CircularProgress } from '@mui/material'

import * as S from './styles'
import { CreatePostProps } from './types'
const CreatePost: React.FC<CreatePostProps> = ({
  html,
  isHtmlEmpty,
  modules,
  handleChangeHtml,
  isLoading,
  isErrorCreatePost,
  isLoadingCreatePost,
  createPost,
  t
}) => {
  if (isLoading) {
    return <></>
  }

  return (
    <S.ContainerCard style={{ paddingTop: 8, width: '100%' }}>
      <ReactQuill
        theme="snow"
        value={html}
        onChange={handleChangeHtml}
        modules={modules}
        placeholder={t('CREATED_POST.POST_A_NOTICE_YOUR_MEMBERS')}
      />
      <S.ContainerButton>
        <Button variant="contained" color="primary" onClick={createPost} fullWidth disabled={isHtmlEmpty}>
          {isLoadingCreatePost ? <CircularProgress size={24} color='info'/> : t('CREATED_POST.PUBLISH')}
        </Button>
      </S.ContainerButton>
    </S.ContainerCard>
  )
}

export default CreatePost
