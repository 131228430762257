import React from 'react'

import { Grid } from '@mui/material'

import Skeleton from '../../components/Skeleton'
import * as S from './styles'
import { useAuthIFrame } from './useAuthIFrame'

const AuthIFrame: React.FC = () => {
  useAuthIFrame()

  return (
    <Grid container flexDirection='column' alignItems='center' paddingBottom={2}>
      <S.ContainerAuthIFrame>
        {Array(4).fill(null).map((_, index) => <Skeleton key={index} />)}
      </S.ContainerAuthIFrame>
    </Grid>
  )
}

export default AuthIFrame
