import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ChatOutlined } from '@mui/icons-material'
import { Grid, Button, Typography } from '@mui/material'
import { TFunction } from 'i18next'

import { ListPostProps } from '../../../pages/Home/components/Posts/ListPost/types'
import { IReaction } from '../../../services/PostService/types'
import { adapterApiReactions, objLiteralReactions } from '../../../utils/reactions'
import { ReactionBarSelector, ReactionCounter } from '../../ReactionComponent'
import * as S from '../styles'
import PostReactions from './PostReactions'

type Props = Pick<ListPostProps,
'handleMouseEnter' |
'handleMouseLeave' |
'isLoadingReaction' |
'handleSelectReaction' |
'emailLogged' |
'matches' |
'matches320' |
'isToggleReaction'>
& {
  postId: string;
  reactions?: IReaction[];
  postReactingId: string;
  isSinglePost: boolean;
  t: TFunction
  handleOpenReactionsDialog: () => void;
  commentCount: string;
};

const PostActions: React.FC<Props> = ({
  reactions,
  postId,
  emailLogged,
  isLoadingReaction,
  handleSelectReaction,
  handleMouseEnter,
  handleMouseLeave,
  handleOpenReactionsDialog,
  isToggleReaction,
  matches,
  matches320,
  postReactingId,
  isSinglePost,
  t,
  commentCount
}) => {
  const navigate = useNavigate()
  const isDisabled = Boolean(isLoadingReaction && postReactingId === postId)
  const commentCountNumber = Number(commentCount)

  const handleSeeComments = () => {
    navigate(`/post/${postId}`)
  }

  return (
    <S.ContainerCardActions>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {reactions && reactions.length > 0
          ? (
            <ReactionCounter
              user={emailLogged}
              iconSize={20}
              reactions={adapterApiReactions(reactions, emailLogged)}
              onClick={handleOpenReactionsDialog}
              showOthersAlways={false}
              showTotalOnly={matches}
              numberEmojisView={matches320 ? 3 : Infinity}
            />)
          : <div/>}
        {!isSinglePost && commentCountNumber > 0
          ? (<Typography variant="body2">{t('LIST_POST.COMMENT_COUNT', { count: commentCountNumber })}</Typography>)
          : null
        }
      </div>
      <S.ContainerActions>
        <Grid
          position='relative'
          display='flex'
          alignItems='center'
          onMouseEnter={isDisabled ? undefined : () => handleMouseEnter(postId)}
          onMouseLeave={isDisabled ? undefined : () => handleMouseLeave(postId)}
        >
          <Button
            disabled={isDisabled}
            aria-label="like"
            color='secondary'
            onClick={() => {
              if (reactions && reactions.length > 0 && reactions.some(reaction => reaction.user_has_reacted)) {
                reactions.forEach(reaction => {
                  if (reaction.user_has_reacted) {
                    handleSelectReaction(reaction.reaction, postId, isSinglePost)
                  }
                })
              } else {
                handleSelectReaction('U+1F44D', postId, isSinglePost)
              }
            }}
          >
            <PostReactions
              reactions={reactions}
            />
          </Button>
          {isToggleReaction[postId]
            ? <Grid position='absolute' bottom={38} zIndex={1}>
              <ReactionBarSelector
                iconSize={24}
                reactions={Object.values(objLiteralReactions)}
                onSelect={(key) => handleSelectReaction(key, postId, isSinglePost)}
              />
            </Grid>
            : null
          }
        </Grid>
        {!isSinglePost
          ? (
            <Button
              color='secondary'
              onClick={handleSeeComments}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                <ChatOutlined fontSize="small"/>{t('LIST_POST.COMMENT_BUTTON')}
              </div>
            </Button>)
          : null}
      </S.ContainerActions>
    </S.ContainerCardActions>
  )
}

export default PostActions
