import React, { useCallback, useMemo, useRef, useState } from 'react'
import ReactQuill from 'react-quill'

import { SendOutlined } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import { TFunction } from 'i18next'

import { employeeService } from '../../../services/EmployeeService'
import { postService } from '../../../services/PostService'
import { capitalizeText } from '../../../utils/global'
import ToastNotification from '../../ToastNotification'
import { ContainerButtonQuill, QuillContainer } from '../styles'

type InputCommentPropsType = {
  postId: string;
  onSuccess: () => void;
  t: TFunction;
};

const InputComment = ({ postId, onSuccess, t }: InputCommentPropsType) => {
  const [html, setHtml] = useState('')
  const [isHtmlEmpty, setIsHtmlEmpty] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  const handleMention = useCallback(
    async (searchTerm: string, renderList: any) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current?.abort()
        }

        abortControllerRef.current = new AbortController()

        if (searchTerm.toUpperCase().includes('TODOS')) {
          return renderList(
            [
              {
                id: 'ALL',
                value: t('MENTION.MENTION_ALL'),
                email: 'ALL'
              }
            ],
            searchTerm
          )
        }

        const employeesData = await employeeService.getEmployees(searchTerm, abortControllerRef.current?.signal)

        const mappedEmployeeData = employeesData.content.map((employee) => ({
          id: employee.id,
          value: capitalizeText(employee.name),
          email: employee.email
        }))

        renderList(mappedEmployeeData, searchTerm)
      } catch (ex) {
        console.warn(ex)
        renderList([], searchTerm)
      }
    },
    [t]
  )

  const modules = useMemo(
    () => ({
      toolbar: false,
      clipboard: {
        matchVisual: true
      },
      mention: {
        allowedChars: /^[A-Za-z\sÁÃáãÓÕóõÉÊéê]*$/,
        mentionDenotationChars: ['@'],
        dataAttributes: ['email'],
        minChars: 3,
        defaultMenuOrientation: 'top',
        source: handleMention,
        renderLoading: () => t('LIST_POST.LOADING'),
        /*
        As duas propriedades abaixo são necessárias
        para quando o usuário digitar um email não habilitar a busca da menção
      */
        isolateCharacter: true,
        allowInlineMentionChar: true
      }
    }),
    [handleMention, t]
  )

  const sendComment = useCallback(async () => {
    try {
      setLoading(true)

      await postService.createComment({
        postId,
        comment: html
      })
      setHtml('')
      onSuccess()
    } catch (error) {
      ToastNotification({
        type: 'error',
        id: 'createComment',
        className: 'toast-error',
        content: t('VIEW.FAIL_CREATE_COMMENT')
      })
    } finally {
      setLoading(false)
    }
  }, [html, onSuccess, postId, t])

  const validateHtml = (editor: ReactQuill.UnprivilegedEditor) => {
    if ((editor.getContents().ops || []).length !== 1) setIsHtmlEmpty(false)
    else setIsHtmlEmpty(editor.getText().trim().length === 0)
  }

  const handleChangeHtml = (value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
    setHtml(value)
    validateHtml(editor)
  }

  const handleButtonClick = () => {
    if (!isHtmlEmpty && !loading) {
      sendComment()
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <ContainerButtonQuill isFocused={isFocused}>
      <div style={{ flex: 1, maxWidth: '96%' }}>
        <QuillContainer>
          <ReactQuill
            theme="snow"
            value={html}
            onChange={handleChangeHtml}
            onFocus={handleFocus}
            onBlur={handleBlur}
            modules={modules}
            placeholder={t('VIEW.LEAVE_A_COMMENT')}
          />
        </QuillContainer>
      </div>
      <IconButton
        onClick={handleButtonClick}
        disableRipple disabled={isHtmlEmpty || loading}
      >
        {loading ? <CircularProgress size={24} color="primary" /> : <SendOutlined fontSize="medium" color={isHtmlEmpty ? 'disabled' : 'primary'} />}
      </IconButton>
    </ContainerButtonQuill>
  )
}

export default InputComment
