/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Typography } from '@mui/material'

import { ListPostProps } from '../../pages/Home/components/Posts/ListPost/types'
import { IPost } from '../../services/PostService/types'
import PostActions from './components/PostActions'
import PostComments from './components/PostComments'
import PostCommentsItem from './components/PostCommentsItem'
import PostContent from './components/PostContent'
import PostHeader from './components/PostHeader'
import ReactionsInfoDialog from './components/ReactionsInfoDialog'
import * as S from './styles'

type Props = Pick<ListPostProps,
  'deletePost' |
  'isLoadingReaction' |
  'isLoadingDelete' |
  'handleSelectReaction' |
  'handleInViewChange' |
  'handleMouseEnter' |
  'handleMouseLeave' |
  'isToggleReaction' |
  'emailLogged' |
  'isModerator' |
  'matches' |
  'matches320' |
  't' |
  'postId'>
  & {
    isSinglePost?: boolean;
    item: IPost;
  }

const PostItem: React.FC<Props> = ({
  item,
  isToggleReaction,
  emailLogged,
  isModerator,
  matches,
  matches320,
  isLoadingReaction,
  isLoadingDelete,
  postId,
  isSinglePost = false,
  deletePost,
  handleSelectReaction,
  handleMouseEnter,
  handleMouseLeave,
  handleInViewChange,
  t
}) => {
  const [showReactionsDialog, setShowReactionsDialog] = useState(false)

  const [ref] = useInView({
    onChange: (inView) => {
      if (inView && handleInViewChange && emailLogged !== item?.author_email && !item?.users_views?.viewed) {
        handleInViewChange(item.id, isSinglePost)
      }
    }
  })

  const renderPostCommentsContent = () => {
    if (!isSinglePost && !item.comments.length) {
      return null
    }

    if (!isSinglePost && item.comments.length > 0) {
      const [comment] = item.comments

      return (
        <S.ContainerComments>
          <PostCommentsItem
            t={t}
            comment={comment}
            showAllAnswers={isSinglePost}/>
        </S.ContainerComments>
      )
    }

    return (
      <PostComments
        t={t}
        postId={item.id}
        showAllAnswers={isSinglePost}
      />
    )
  }

  const handleOpenDialog = () => {
    setShowReactionsDialog(true)
  }

  const handleCloseDialog = () => {
    setShowReactionsDialog(false)
  }

  const renderViewInformation = () => {
    if (!item.users_views?.count) {
      return null
    }

    return (
      <S.ConstainerView>
        <Typography variant="caption" color="text.secondary">
          {t('LIST_POST.VIEWED_BY')} {item.users_views.count}
        </Typography>
      </S.ConstainerView>
    )
  }

  return (
    <S.ContainerCard key={item.id} ref={ref}>
      <PostHeader
        postId={item.id}
        postDeletingId={postId}
        authorName={item.author_name}
        authorAvatar={item.author_avatar}
        authorEmail={item.author_email}
        createdAt={item.created_at}
        deletePost={deletePost}
        emailLogged={emailLogged}
        isModerator={isModerator}
        isLoadingDelete={isLoadingDelete}
        isSinglePost={isSinglePost}
      />
      {renderViewInformation()}
      <PostContent
        content={item.content}
      />
      <PostActions
        isToggleReaction={isToggleReaction}
        postId={item.id}
        isSinglePost={isSinglePost}
        isLoadingReaction={isLoadingReaction}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        handleSelectReaction={handleSelectReaction}
        handleOpenReactionsDialog={handleOpenDialog}
        matches={matches}
        emailLogged={emailLogged}
        reactions={item.reactions}
        matches320={matches320}
        postReactingId={postId}
        t={t}
        commentCount={item.comments_count}
      />
      {renderPostCommentsContent()}
      {showReactionsDialog && item.reactions
        ? (
        <ReactionsInfoDialog
        postId={item.id}
        showDialog={showReactionsDialog}
        onClose={handleCloseDialog}
        t={t}
        />
          )
        : null}
    </S.ContainerCard>
  )
}

export default PostItem
