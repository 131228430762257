import { api } from '../../helpers/axios'
import { IEmployees } from './types'

async function getEmployees (searchTerm: string, signal: AbortSignal): Promise<IEmployees> {
  const response = await api.get('/users', {
    params: {
      name: searchTerm
    },
    signal
  })
  return response.data
}

export const employeeService = {
  getEmployees
}
