import React from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Button, Grid, Typography } from '@mui/material'

import { useNotFound } from './useNotFound'

const NotFound = () => {
  const { handleBack, t } = useNotFound()
  const title = t('TITLE_NOT_FOUND')
  const error = t('NOT_FOUND_404')
  const description = t('DESCRIPTION_NOT_FOUND')
  return (
    <>
      <Grid container flexDirection="column" justifyContent="flex-start" alignItems="center" paddingTop={8}>
        <InfoIcon style={{ fontSize: 40, margin: 8 }} />
        <Typography variant="h5" margin={1}>{title} - {error}</Typography>
        <Typography variant="h6" margin={1}>{description}</Typography>
        <Button variant="contained" color="primary" onClick={handleBack}>
          {t('BUTTON_GO_BACK')}
        </Button>
      </Grid>
    </>
  )
}

export default NotFound
