import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#01009A'
    },
    secondary: {
      main: '#404040'
    },
    error: {
      main: red.A400
    },
    info: {
      main: '#fff'
    }
  },
  typography: {
    fontFamily: 'Inter',
    button: {
      textTransform: 'none'
    }
  }
})

export default theme
