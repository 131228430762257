import { Quill } from 'react-quill'

import ImageCompress from 'quill-image-compress'
import ImageResize from 'quill-image-resize-module-react-mobile'
import ImageUploader from 'quill-image-uploader'
import 'quill-mention'

import 'react-quill/dist/quill.snow.css'
import 'quill-mention/dist/quill.mention.min.css'
import 'quill-image-uploader/dist/quill.imageUploader.min.css'

const BaseImageFormat = Quill.import('formats/image')
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
]

class ImageFormat extends BaseImageFormat {
  static formats (domNode: { hasAttribute: (arg0: string) => any; getAttribute: (arg0: string) => any }) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute) as string
      }
      return formats
    }, {} as Record<string, string>)
  }

  format (name: string, value: any) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register(ImageFormat, true)
Quill.register('modules/imageCompress', ImageCompress)
Quill.register('modules/imageUploader', ImageUploader)
Quill.register('modules/imageResize', ImageResize)
