import React, { useEffect } from 'react'
import { createContext, useState } from 'react'

import { jwtDecode } from 'jwt-decode'

import { registerInterceptor } from '../../helpers/axios'
import cookiesHelper from '../../helpers/cookies'
import { authService } from '../../services/AuthService'
import { AuthTokenCredentials, AuthCredentialsService } from './types'

export const AuthCredentialsContext = createContext<AuthCredentialsService>({
  authCredentials: null,
  saveCredentials: async () => { },
  removeCredentials: async () => { },
  updateAuthCredentials: () => { },
  isLoading: true
})

export const AuthCredentialsProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const [authCredentials, setAuthCredentials] = useState<AuthTokenCredentials | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // saveCredentials()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const interceptor = registerInterceptor({
      authCredentials
    })

    // remove listener when component unmount
    return interceptor
  }, [authCredentials])

  const saveCredentials = async (token: string): Promise<void> => {
    try {
      // const storageToken = await authService.getToken()

      if (token) {
        const decodedToken = jwtDecode(token)

        const authCredentials = {
          token,
          isModerator: false,
          // @ts-ignore
          emailLogged: decodedToken.unique_name
        }

        authService.setLocalStorage(authCredentials)
        setAuthCredentials(authCredentials)
        cookiesHelper.setItem('authorization', token)
      }
    } catch (error) {
      console.warn('Catch - saveCredentials', error)
    } finally {
      setIsLoading(false)
    }
  }

  const updateAuthCredentials = (isModerator: boolean) => {
    try {
      if (authCredentials) {
        const newAuthCredentials = {
          token: authCredentials.token,
          emailLogged: authCredentials.emailLogged,
          isModerator
        }
        authService.setLocalStorage(newAuthCredentials)
        setAuthCredentials(newAuthCredentials)
      }
    } catch (error) {
      console.warn('Catch - updateAuthCredentials', error)
    }
  }

  const removeCredentials = (): void => {
    authService.removeToken()
    authService.removeLocalStorage()
    setAuthCredentials(null)
  }

  return (
    <AuthCredentialsContext.Provider
      value={{ authCredentials, saveCredentials, removeCredentials, isLoading, updateAuthCredentials }}>
      {children}
    </AuthCredentialsContext.Provider>
  )
}
