import { CardActions, Grid } from '@mui/material'
import styled, { css } from 'styled-components'

interface ContainerButtonQuillProps {
  isFocused: boolean;
}

export const ContainerCard = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 6,
  sm: 8
})`
  border: 1.2px solid #D0D0D0;
  background: #FFFFFF;
  border-radius: 16px;
  margin: 8px 0 !important;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ContainerCardSkeleton = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 6,
  sm: 8
})`
  border: 1.2px solid #D0D0D0;
  background: #FFFFFF;
  border-radius: 16px;
  margin: 8px 0 !important;
  width: 100%;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
`

export const ContainerCardActions = styled(CardActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 16px 0px 16px;
  padding: 8px 0 0 0;
`

export const ContainerActions = styled(CardActions)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-top: 1.2px solid #D0D0D0;
  margin: 8px 0px 0px 0px !important;
  padding: 0
`

export const ContainerButton = styled(Grid)`
  margin-top: 16px;
  margin-bottom: 24px;
`

export const ConstainerView = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
`

export const ContainerComments = styled(CardActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 16px 8px 16px;
  border-top: 1.2px solid #D0D0D0;
  flex: 1;
  `

export const ContainerCommentsAnswers = styled(CardActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 16px 8px 16px;
  flex: 1;
  `
export const ContainerInfiniteScroll = styled.div`
  width: 100%;
`

export const ReactionInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -6px;
  right: -10px; 
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  padding-left: 2px; 
  padding-right: 2px; 
  border-radius: 50px; 
`
export const QuillContainer = styled.div`
  .ql-container.ql-snow {
    border: none;
  }
`

export const ContainerButtonQuill = styled.div<ContainerButtonQuillProps>`
  position: fixed;
  bottom: 16px;
  left: 8px;
  right: 8px;
  z-index: 2;
  padding: 8px;
  margin: 10px 10px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  border: 1px solid #D0D0D0;
  transition: border-color 0.3s;
  border-radius: 10px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 2px solid #312D5E;
    `}
`

export const ContainerButtonQuillAnswers = styled.div<ContainerButtonQuillProps>`
  padding: 8px;
  max-width: 330px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #D0D0D0;
  transition: border-color 0.3s;
  border-radius: 10px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 2px solid #312D5E;
    `}
`
