import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import PostItem from '../../../../../components/PostItem/PostItem'
import Skeleton from '../../../../../components/Skeleton'
import { ROUTES } from '../../../../../routes/routes.path'
import { usePost } from '../usePost'
import * as S from './styles'

const ViewPost: React.FC = () => {
  const { postId } = useParams()
  const navigate = useNavigate()

  const {
    handleSelectReaction,
    handleMouseEnter,
    handleMouseLeave,
    handleInViewChange,
    deletePost,
    getPostById,
    isLoadingDelete,
    isLoadingReaction,
    postToView,
    isGettingPostById,
    isGetPostByIdError,
    t,
    emailLogged,
    isModerator,
    isToggleReaction,
    matches,
    matches320
  } = usePost()

  useEffect(() => {
    if (postId) {
      getPostById(postId)
    }
  }, [])

  const renderContent = () => {
    if (isGettingPostById) {
      return (
        <S.ContainerLoading>
          <Skeleton />
        </S.ContainerLoading>
      )
    }

    if (isGetPostByIdError || !postId) {
      return (
        <div style={{ flexWrap: 'wrap' }}>
          {t('VIEW.CANT_LOAD_POST')} 😢
        </div>
      )
    }

    if (!postToView) return null

    return (
      <PostItem
        item={postToView}
        key={postToView.id}
        handleSelectReaction={handleSelectReaction}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        handleInViewChange={handleInViewChange}
        deletePost={deletePost}
        isLoadingReaction={isLoadingReaction}
        isLoadingDelete={isLoadingDelete}
        isToggleReaction={isToggleReaction}
        isSinglePost
        emailLogged={emailLogged}
        isModerator={isModerator}
        matches={matches}
        matches320={matches320}
        t={t}
        postId={postId}
      />
    )
  }

  const handleGoBack = () => {
    navigate(ROUTES.FEED)
  }

  return (
    <S.ContainerPosts
      container
      flexDirection='column'
      alignItems='center'
      wrap='nowrap'
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
        <IconButton onClick={handleGoBack}>
          <ArrowBack />
        </IconButton>
      </div>
      {renderContent()}
    </S.ContainerPosts>
  )
}

export default ViewPost
