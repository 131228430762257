import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { LinearProgress } from '@mui/material'

import { useAuthCredentials } from '../hooks/useAuthCredentials'
import AuthIFrame from '../pages/AuthIFrame/AuthIFrame'
import ViewPost from '../pages/Home/components/Posts/ViewPost/ViewPost'
import { Home } from '../pages/Home/Home'
import NotFound from '../pages/NotFound/NotFound'
import { authService } from '../services/AuthService'
import { PrivateRoute } from './PrivateRoute'
import { ROUTES } from './routes.path'

export const RoutesDom: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const { authCredentials, saveCredentials } = useAuthCredentials()

  const validateInitialToken = async () => {
    try {
      const storageToken = await authService.getToken()

      if (storageToken) {
        saveCredentials(storageToken)
      }
    } catch (ex) {
      setLoading(false)
      console.warn(ex)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    validateInitialToken()
  }, [])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Routes>
      <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      <Route path={ROUTES.AUTH} element={!authCredentials ? <AuthIFrame /> : <Navigate to='/home' replace />} />
      <Route path={ROUTES.EMPTY} element={<Navigate to='/home' replace />} />
      <Route path={ROUTES.FEED} element={<PrivateRoute component={Home} />} />
      <Route path={ROUTES.VIEW} element={<PrivateRoute component={ViewPost} />} />
    </Routes>
  )
}
