const {
  REACT_APP_NODE_ENV,
  REACT_APP_FEED_API_BASE_URL,
  REACT_APP_API_REMOTE_URL
} = process.env

const appConfig = {
  env: REACT_APP_NODE_ENV,
  apiBaseUrl: REACT_APP_FEED_API_BASE_URL,
  ociAttachmentUrl: '/api/oci-attachments',
  defaultLanguage: 'pt',
  apiRemoteUrl: REACT_APP_API_REMOTE_URL
}

export default appConfig
