export interface Storage {
  getItem: <T = unknown>(key: string) => Promise<T | null>;
  setItem: <T>(key: string, value: T) => void;
  removeItem: (key: string) => void;
}

export let storage: Storage

export const initializeStorage = (storageImpl: Storage) => {
  storage = storageImpl
}
