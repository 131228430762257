import { Grid } from '@mui/material'
import styled from 'styled-components'

export const ContainerInfiniteScroll = styled.div`
  width: 100%;
`

export const ContainerLoading = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 6,
  sm: 8
})`
  width: 100%;
`

export const ContainerButton = styled(Grid)`
  margin-top: 16px;
  margin-bottom: 24px;
`
