import React from 'react'

import { Skeleton, Stack } from '@mui/material'

const CommentAnswersSkeleton = () => {
  return (
    <Stack spacing={2} style={{ margin: 16, flex: 1 }}>
      <Stack direction="row" spacing={1}>
        <Skeleton variant="circular" height={40} width={40} />
        <Skeleton variant="rectangular" height={96} width="25%" style={{ borderRadius: 9 }} />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton variant="circular" height={40} width={40} />
        <Skeleton variant="rectangular" height={96} width="65%" style={{ borderRadius: 9 }} />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton variant="circular" height={40} width={40} />
        <Skeleton variant="rectangular" height={96} width="35%" style={{ borderRadius: 9 }} />
      </Stack>
    </Stack>
  )
}

export default CommentAnswersSkeleton
