import { CardActions, Grid } from '@mui/material'
import styled from 'styled-components'

export const ContainerCardSkeleton = styled(Grid)`
  border: 1.2px solid #D0D0D0;
  background: #FFFFFF;
  border-radius: 16px;
  margin: 8px 0 !important;
  width: 100%;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
`

export const ContainerCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`
