import React, { useState } from 'react'

import { Link, Typography } from '@mui/material'
import { TFunction } from 'i18next'

import { ICommentAnswer } from '../../../services/PostService/types'
import { dateUtils } from '../../../utils/date'
import { capitalizeText, truncatedContent } from '../../../utils/global'
import Avatar from './Avatar'

type PostCommentsAnswerItemPropsType = {
  t: TFunction
  answer:ICommentAnswer;
}

const CHARACTER_LENGTH = 80

const PostCommentsAnswersItem = ({ t, answer }: PostCommentsAnswerItemPropsType) => {
  const [isExpandedContent, setIsExpandedContent] = useState(true)

  const handleClickExpand = () => {
    setIsExpandedContent(oldState => !oldState)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '8px 0px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        <Avatar authorName={capitalizeText(answer.user_name)} authorEmail={answer.user_email} />
        <div style={{ backgroundColor: '#f0f2f5', padding: 12, borderRadius: 9 }}>
          <div style={{ marginBottom: 8 }}>
            <Typography variant="body2">{capitalizeText(answer.user_name)}</Typography>
            <Typography variant="body2" style={{ color: 'rgb(0 0 0 / 60%)' }}>{dateUtils.formatRelative(answer.created_at)}</Typography>
          </div>
          <div className="ql-editor">
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
              <p style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'anywhere' }}>
                <div dangerouslySetInnerHTML={{ __html: isExpandedContent ? answer.comment_answer : truncatedContent(answer.comment_answer, CHARACTER_LENGTH) }} />
                {answer.comment_answer.length > CHARACTER_LENGTH && (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleClickExpand}
                  >
                    {isExpandedContent ? t('COMMENT_ITEM.SEE_LESS') : t('COMMENT_ITEM.SEE_MORE')}
                  </Link>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCommentsAnswersItem
