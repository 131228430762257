import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill, { Quill } from 'react-quill'
import { useNavigate } from 'react-router-dom'

import { useMediaQuery } from '@mui/material'
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import ToastNotification from '../../../../components/ToastNotification'
import appConfig from '../../../../config/app'
import { QueryKeys } from '../../../../helpers/queryKeys'
import { useAuthCredentials } from '../../../../hooks/useAuthCredentials'
import { authService } from '../../../../services/AuthService'
import { employeeService } from '../../../../services/EmployeeService'
import { postService } from '../../../../services/PostService'
import { ICreatePost, ICreateReaction, IDeletePost, IPost } from '../../../../services/PostService/types'
import { IBasePageResponse } from '../../../../services/types'
import { uploadService } from '../../../../services/UploadService'
import { capitalizeText } from '../../../../utils/global'

export const usePost = () => {
  const queryClient = useQueryClient()
  const { authCredentials, updateAuthCredentials, isLoading: loadingCredentials } = useAuthCredentials()
  const navigate = useNavigate()

  const matches = useMediaQuery('(max-width:600px)')
  const matches320 = useMediaQuery('(max-width:288px)')
  const { t } = useTranslation('PAGES', { keyPrefix: 'HOME.COMPONENTS.POST' })
  const { mutate: mutateUploadFile } = useMutation(uploadService.uploadFile)

  const [postImgKey, setPostImgKey] = useState<number[]>([])
  const [postToDeleteId, setPostToDeleteId] = useState<string>('')
  const [postReactingId, setPostReactingId] = useState<string>('')
  const [postToView, setPostToView] = useState<IPost|undefined>()
  const [html, setHtml] = useState('')
  const [isHtmlEmpty, setIsHtmlEmpty] = useState(true)
  const [isToggleReaction, setIsToggleReaction] = useState<{ [postId: string]: boolean }>({})

  const { mutate: mutateViewUserPost } = useMutation(postService.viewUserPost, {
    onSuccess: (result, sendRequest) => {
      if (sendRequest.singlePost) {
        const viedPost = updateUserViewInPost(postToView!)
        setPostToView(viedPost)
      }

      queryClient.setQueryData<InfiniteData<IBasePageResponse<IPost[]>>>(
        [QueryKeys.Posts],
        (oldData) => updateViewUserInPage(oldData, result.post_id)
      )
    }
  })

  const { mutate: mutateCreatedPost, isError, isLoading } = useMutation(postService.createPost, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.Posts])
      setHtml('')
    }
  })

  const { mutate: mutateDeletePost, isLoading: isLoadingDelete } = useMutation(postService.deletePost, {
    onSuccess: (result, sendRequest) => {
      if (sendRequest.singlePost) {
        setPostToView(undefined)
        navigate('/home')
      } else {
        queryClient.setQueryData<InfiniteData<IBasePageResponse<IPost[]>>>(
          [QueryKeys.Posts],
          (oldData) => updatePageContentOnDelete(oldData, postToDeleteId!)
        )
      }

      ToastNotification({
        type: 'success',
        id: 'deletePost',
        className: 'toast-success',
        content: t('DELETED_POST')
      })
    },
    onError: () => {
      ToastNotification({
        type: 'error',
        id: 'deletePost',
        className: 'toast-error',
        content: t('DELETED_POST_FAIL')
      })
    },
    onSettled: () => {
      setPostToDeleteId('')
    }
  })

  const { mutate: mutateReactionPost, isLoading: isLoadingReaction } = useMutation(postService.reactToPost, {
    onSuccess: (result, sendRequest) => {
      let reaction = sendRequest.reaction
      let isAddOperation = false

      if (result) {
        reaction = result.reaction
        isAddOperation = true
      }

      if (sendRequest.singlePost) {
        const reactedPostUpdated = updateReactionsInPost(postToView!, reaction, isAddOperation)
        setPostToView(reactedPostUpdated)
      } else {
        queryClient.setQueryData<InfiniteData<IBasePageResponse<IPost[]>>>(
          [QueryKeys.Posts],
          (oldData) => updateReactionsInPage(oldData, postReactingId, reaction, isAddOperation)
        )
      }
    },
    onSettled: () => {
      setPostReactingId('')
    }
  })

  const { mutate: mutateModeratorPermission, isLoading: isLoadingModerator } = useMutation(authService.getModeratorPermission, {
    onSuccess: (result) => {
      updateAuthCredentials(result.is_moderator)
    },
    retry: 2
  })

  const { mutate: mutateGetPostById, isLoading: isGettingPostById, isError: isGetPostByIdError } = useMutation(postService.getPostById, {
    onSuccess: (result) => {
      setPostToView(result)
    }
  })

  const handlerUploadImage = (imageBase64URL: string, imageBlob: File, editor: any) => {
    const formData = new FormData()
    formData.append('files', imageBlob, imageBlob.name)
    mutateUploadFile(formData, {
      onSuccess: (result) => {
        const range = editor.getSelection()
        setPostImgKey([Number(result.id)])
        editor.insertEmbed(range.index, 'image', `${appConfig.ociAttachmentUrl}/${result.file_key}`, 'user')
        const block = editor.getLine(range.index)
        const imageElement = block[range.index].domNode.querySelector('img')
        if (imageElement) {
          imageElement.style.display = 'block'
          imageElement.style.margin = 'auto'
        }
      }
    })
  }

  const abortControllerRef = useRef<AbortController | null>(null)

  const handleMention = useCallback(async (searchTerm: string, renderList: any) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current?.abort()
      }

      abortControllerRef.current = new AbortController()

      if (searchTerm.toUpperCase().includes('TODOS')) {
        return renderList(
          [{
            id: 'ALL',
            value: t('MENTION.MENTION_ALL'),
            email: 'ALL'
          }],
          searchTerm
        )
      }

      const employeesData = await employeeService.getEmployees(
        searchTerm,
        abortControllerRef.current?.signal
      )

      const mappedEmployeeData = employeesData.content.map(employee => ({
        id: employee.id,
        value: capitalizeText(employee.name),
        email: employee.email
      }))

      renderList(mappedEmployeeData, searchTerm)
    } catch (ex) {
      console.warn(ex)
      renderList([], searchTerm)
    }
  }, [t])

  const modules = useMemo(() => ({
    toolbar: ['image'],
    imageUploader: {
      upload: handlerUploadImage
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    },
    imageCompress: {
      quality: 1,
      maxHeight: 1500,
      maxWidth: 600,
      debug: false,
      insertIntoEditor: handlerUploadImage
    },
    clipboard: {
      matchVisual: false
    },
    mention: {
      allowedChars: /^[A-Za-z\sÁÃáãÓÕóõÉÊéê]*$/,
      mentionDenotationChars: ['@'],
      dataAttributes: ['email'],
      minChars: 3,
      defaultMenuOrientation: 'top',
      source: handleMention,
      renderLoading: () => t('LIST_POST.LOADING'),
      /*
        As duas propriedades abaixo são necessárias
        para quando o usuário digitar um email não habilitar a busca da menção
      */
      isolateCharacter: true,
      allowInlineMentionChar: true
    }
  }), [])

  const query = useInfiniteQuery(
    [QueryKeys.Posts],
    {
      queryFn: ({ pageParam = 1 }) => postService.getPosts({
        page: pageParam,
        limit: 10,
        orders: Array(JSON.stringify(['created_at', 'desc']))
      }),
      getNextPageParam: ({ metadata }) => {
        const nextPage = metadata.page + 1
        return nextPage <= metadata.pages ? nextPage : undefined
      },
      refetchOnWindowFocus: false,
      retry: 0
    }
  )

  const items = useMemo(() => {
    return query.data
      ? query.data.pages.reduce<IPost[]>((prev, curr) => {
        return [...prev, ...curr.result]
      }, [])
      : []
  }, [query.data])

  const createdPost = () => {
    if (postImgKey) {
      const body: ICreatePost = {
        title: 'Post',
        content: html,
        attachments_ids: postImgKey
      }
      mutateCreatedPost(body)
    }
  }

  const deletePost = (postId: string, singlePost: boolean) => {
    const params: IDeletePost = {
      post_id: postId,
      singlePost
    }
    setPostToDeleteId(postId)
    mutateDeletePost(params)
  }

  const getPostById = (postId: string) => {
    setPostToView(undefined)
    mutateGetPostById(postId)
  }

  const validateHtml = (editor: ReactQuill.UnprivilegedEditor) => {
    if ((editor.getContents().ops || []).length !== 1) setIsHtmlEmpty(false)
    else setIsHtmlEmpty(editor.getText().trim().length === 0)
  }

  const handleChangeHtml = (value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
    setHtml(value)
    validateHtml(editor)
  }

  const handleMouseEnter = (postId: string) => {
    setIsToggleReaction(prevState => ({
      ...prevState,
      [postId]: true
    }))
  }

  const handleMouseLeave = (postId: string) => {
    setIsToggleReaction(prevState => ({
      ...prevState,
      [postId]: false
    }))
  }

  const handleSelectReaction = (key: string, postId: string, singlePost: boolean) => {
    const reaction: ICreateReaction = {
      post_id: postId,
      reaction: key,
      singlePost
    }
    mutateReactionPost(reaction)
    setPostReactingId(postId)
    setIsToggleReaction(prevState => ({
      ...prevState,
      [postId]: false
    }))
  }

  const updateReactionsInPost = (post: IPost, reaction: string, isAddOperation: boolean): IPost => {
    const existingReactions = post.reactions ? [...post.reactions] : []
    const newReaction = {
      count: 1,
      reaction,
      user_has_reacted: true
    }

    if (isAddOperation) {
      const differentReactionIndex = existingReactions.findIndex(
        (r) => r.reaction !== reaction && r.user_has_reacted
      )

      if (differentReactionIndex !== -1) {
        existingReactions.splice(differentReactionIndex, 1)
      }

      existingReactions.push(newReaction)
    } else {
      const existingReactionIndex = existingReactions.findIndex(
        (r) => r.reaction === reaction && r.user_has_reacted
      )

      if (existingReactionIndex !== -1) {
        /*
          Quando o usuário logado reage à um post o backend agrupa as reações por tipo.
          No momento em que o usuário logado remove sua reação, ao passar por esse trecho, todas as reações eram removidas, uma vez que o user_has_reacted estava como true.
          Aqui contornamos essa situação a fim de manter as demais reações.
        */
        const foundReaction = existingReactions[existingReactionIndex]
        if (Number(foundReaction.count) > 1 && foundReaction.user_has_reacted) {
          existingReactions.splice(existingReactionIndex, 1, {
            ...foundReaction,
            count: Number(foundReaction.count) - 1,
            user_has_reacted: false
          })
        } else {
          existingReactions.splice(existingReactionIndex, 1)
        }
      }
    }

    return {
      ...post,
      reactions: existingReactions
    }
  }

  const updateReactionsInPage = (
    oldData: InfiniteData<IBasePageResponse<IPost[]>> | undefined,
    postId: string,
    reaction: string,
    isAddOperation: boolean
  ): InfiniteData<IBasePageResponse<IPost[]>> | undefined => {
    if (!oldData || !oldData.pages) {
      return oldData
    }

    const targetPageIndex = oldData.pages.findIndex((p) => {
      return p.result.some((item) => item.id === postId)
    }
    )

    if (targetPageIndex === -1) {
      return oldData
    }

    const updatedResults = oldData.pages[targetPageIndex].result.map((item) => {
      if (item.id === postId) {
        return updateReactionsInPost(item, reaction, isAddOperation)
      }
      return item
    })

    const updatedPage = {
      ...oldData.pages[targetPageIndex],
      result: updatedResults
    }

    const updatedPages = [...oldData.pages]
    updatedPages[targetPageIndex] = updatedPage

    return { ...oldData, pages: updatedPages }
  }

  const updateUserViewInPost = (
    post: IPost
  ): IPost => {
    const initialCount = 1

    return { ...post, users_views: { ...post.users_views, count: post.users_views?.count || initialCount, viewed: true } }
  }

  const updateViewUserInPage = (
    oldData: InfiniteData<IBasePageResponse<IPost[]>> | undefined,
    postId: string
  ): InfiniteData<IBasePageResponse<IPost[]>> | undefined => {
    if (!oldData || !oldData.pages) {
      return oldData
    }

    const targetPageIndex = oldData.pages.findIndex((p) =>
      p.result.some((item) => item.id === postId)
    )

    if (targetPageIndex === -1) {
      return oldData
    }

    const updatedPage = {
      ...oldData.pages[targetPageIndex],
      result: oldData.pages[targetPageIndex].result.map((item) =>
        item.id === postId
          ? updateUserViewInPost(item)
          : item
      )
    }

    const updatedPages = [...oldData.pages]
    updatedPages[targetPageIndex] = updatedPage

    return { ...oldData, pages: updatedPages }
  }

  const updatePageContentOnDelete = (
    oldData: InfiniteData<IBasePageResponse<IPost[]>> | undefined,
    postId: string
  ): InfiniteData<IBasePageResponse<IPost[]>> | undefined => {
    if (!oldData || !oldData.pages) {
      return oldData
    }
    const targetPageIndex = oldData.pages.findIndex((p) =>
      p.result.some((item) => item.id === postId)
    )

    if (targetPageIndex === -1) {
      return oldData
    }

    const postIndex = oldData.pages[targetPageIndex].result.findIndex((item) => (item.id === postId))

    if (postIndex === -1) {
      return oldData
    }

    const updatedPage = {
      ...oldData.pages[targetPageIndex],
      result: oldData.pages[targetPageIndex].result.filter((item, index) => index !== postIndex)
    }

    const updatedPages = [...oldData.pages]
    updatedPages[targetPageIndex] = updatedPage

    return { ...oldData, pages: updatedPages }
  }

  const handleInViewChange = (postId: string, singlePost: boolean) => {
    mutateViewUserPost({ postId, singlePost })
  }

  useEffect(() => {
    mutateModeratorPermission()
  }, [])

  return {
    refresh: query.refetch,
    fetchNextPage: query.fetchNextPage,
    handleChangeHtml,
    createdPost,
    handleMouseEnter,
    handleMouseLeave,
    handleSelectReaction,
    handleInViewChange,
    deletePost,
    getPostById,
    list: items,
    postToView,
    hasNextPage: !!query.hasNextPage,
    isError: query.isError,
    isLoading: loadingCredentials || query.isLoading,
    isFetchingNextPage: query.isFetchingNextPage,
    html,
    modules,
    isLoadingCreatePost: isLoading,
    isErrorCreatePost: isError,
    isLoadingReaction,
    isLoadingDelete,
    isLoadingModerator,
    isGettingPostById,
    isGetPostByIdError,
    isHtmlEmpty,
    isToggleReaction,
    matches,
    matches320,
    t,
    emailLogged: authCredentials?.emailLogged,
    isModerator: authCredentials?.isModerator!,
    postId: postToDeleteId || postReactingId
  }
}
