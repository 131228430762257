import React, { Fragment } from 'react'

import { ThumbUpAltOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { IPost } from '../../../services/PostService/types'
import { objLiteralReactions } from '../../../utils/reactions'

type Props = Pick<IPost, 'reactions'>

const PostReactions: React.FC<Props> = ({ reactions }) => {
  if (reactions && reactions.length > 0) {
    const matchingReactions = reactions.find(reaction => reaction.user_has_reacted)
    if (matchingReactions) {
      return (
        <Fragment>
          {objLiteralReactions[matchingReactions.reaction].node}
          <Typography variant="subtitle2" color='primary' marginLeft={1}>
            {objLiteralReactions[matchingReactions.reaction].label}
          </Typography>
        </Fragment>
      )
    }
  }

  return (
    <>
      <ThumbUpAltOutlined fontSize='small' />
      <Typography variant="subtitle2" color='text.secondary' marginLeft={1}>
        Curtir
      </Typography>
    </>
  )
}

export default PostReactions
