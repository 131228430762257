import React from 'react'

import { Typography } from '@mui/material'

import { IReactionResponse } from '../../../services/PostService/types'
import { capitalizeText } from '../../../utils/global'
import { objLiteralReactions } from '../../../utils/reactions'
import * as S from '../styles'
import Avatar from './Avatar'

type ReactionsInfoItemPropsType = {
  item: IReactionResponse
}

const ReactionsInfoItem = ({ item }: ReactionsInfoItemPropsType) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginBottom: 12 }}>
      <div style={{ position: 'relative' }}>
        <Avatar authorName={item.user_name} authorEmail={item.user_email} />
        <S.ReactionInfoItem>
          {objLiteralReactions[item.reaction].node}
        </S.ReactionInfoItem>
      </div>
      <Typography>{capitalizeText(item.user_name)}</Typography>
    </div>
  )
}

export default ReactionsInfoItem
