/* eslint-disable no-undef */
import React from 'react'
import { ToastContent } from 'react-toastify'

import { Typography } from '@mui/material'

import { IToastProps } from './types'

const ToastTypes = (params: IToastProps): ToastContent => {
  const { content } = params
  const toastCss = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '8px'
  }

  const renderToast = (): ToastContent => (
    <span style={toastCss}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* {iconFirst && <Icon fill={Theme.white} size={32} name={iconFirst} variant={variant}/>} */}
        <Typography variant="body1" style={{ padding: 0, margin: 0, textAlign: 'left', paddingLeft: 8 }}>
          {content}
        </Typography>
      </div>
    </span>
  )

  return renderToast()
}

export default ToastTypes
