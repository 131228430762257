import React from 'react'

import { Reaction, ReactionCounterObject } from '../../components/ReactionComponent'
import { IReaction } from '../../services/PostService/types'

export const objLiteralReactions: Record<string, Reaction> = {
  'U+1F44D': { node: <div>👍</div>, label: 'Curtir', key: 'U+1F44D' },
  'U+2764': { node: <div>❤️</div>, label: 'Amei', key: 'U+2764' },
  'U+1F601': { node: <div>😁</div>, label: 'Gargalhada', key: 'U+1F601' },
  'U+1F973': { node: <div>🥳</div>, label: 'Comemorar', key: 'U+1F973' },
  'U+1F44F': { node: <div>👏</div>, label: 'Obrigado', key: 'U+1F44F' },
  'U+1F97A': { node: <div>🥺</div>, label: 'Triste', key: 'U+1F97A' }
}

export const adapterApiReactions = (reactions: IReaction[], emailLogged?: string) => {
  const expandedReactions: ReactionCounterObject[] = []
  reactions.forEach((reaction) => {
    const { reaction: reactionName, count, user_has_reacted: useHasReacted } = reaction
    for (let i = 0; i < count; i++) {
      expandedReactions.push({
        node: objLiteralReactions[reactionName].node,
        label: reactionName,
        by: useHasReacted ? emailLogged! : ''
      })
    }
  })
  return expandedReactions
}
