import React, { useState } from 'react'

import { CardContent, Link } from '@mui/material'

import { truncatedContent } from '../../../utils/global'

const CHARACTER_LENGTH = 811

type Props = {
  content: string;
};

const PostContent: React.FC<Props> = ({ content }) => {
  const [isExpandedContent, setIsExpandedContent] = useState(true)

  const handleClickExpand = () => {
    setIsExpandedContent(oldState => !oldState)
  }

  return (
    <CardContent style={{ padding: 0 }}>
      <div className="ql-editor">
        <div dangerouslySetInnerHTML={{ __html: isExpandedContent ? content : truncatedContent(content, CHARACTER_LENGTH) }} />
        {content.length > CHARACTER_LENGTH && (
          <Link
            component="button"
            variant="body2"
            onClick={handleClickExpand}
          >
            {isExpandedContent ? 'Ver menos' : 'Ver mais'}
          </Link>
        )}
      </div>
    </CardContent>
  )
}

export default PostContent
