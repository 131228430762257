import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuthCredentials } from '../../hooks/useAuthCredentials'

export const useAuthIFrame = () => {
  const { saveCredentials } = useAuthCredentials()
  const { token } = useParams()
  const navigate = useNavigate()

  const validateInitialUrlToken = async () => {
    try {
      if (token) {
        saveCredentials(token)
        return
      }

      navigate('/not-found')
    } catch (ex) {
      console.warn(ex)
    }
  }

  useEffect(() => {
    validateInitialUrlToken()
  }, [])
}
