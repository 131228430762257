import React from 'react'
import { HashRouter } from 'react-router-dom'

import { CssBaseline, ThemeProvider } from '@mui/material'
import './utils/reactQuill'
import './helpers/i18n'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ToastNotificationContainer from './components/ToastNotification/ToastNotificationContainer'
import { AuthCredentialsProvider } from './contexts/Auth'
import { RoutesDom } from './routes'
import GlobalStyle from './styles/global'
import theme from './styles/theme'
import { initializeStorage } from './utils/storage'
import { localStorageWeb } from './utils/storage/implementation/localStorageWeb'

initializeStorage(localStorageWeb)
const queryClient = new QueryClient()

export const App = () => {
  return (
    <AuthCredentialsProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HashRouter>
            <RoutesDom />
          </HashRouter>
          <ToastNotificationContainer />
          <GlobalStyle />
        </ThemeProvider>
      </QueryClientProvider>
    </AuthCredentialsProvider>
  )
}
