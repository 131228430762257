import { Grid } from '@mui/material'
import styled from 'styled-components'

export const ContainerPosts = styled(Grid)`
  min-height: 100vh;
  @media screen and (max-width: 600px) {
    padding: 0 8px 8px 8px;
  }
`
export const ContainerLoading = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 6,
  sm: 8
})`
  width: 100%;
`
