import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuthCredentials } from '../../hooks/useAuthCredentials'

interface PrivateRouteProps {
  component: React.ComponentType
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent }) => {
  const { authCredentials } = useAuthCredentials()

  if (authCredentials) {
    return <RouteComponent />
  }

  return <Navigate to="/auth" replace />
}
