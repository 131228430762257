import React from 'react'

import CreatePost from './CreatePost/CreatedPost'
import ListPost from './ListPost/ListPost'
import * as S from './styles'
import { usePost } from './usePost'

const Posts: React.FC = () => {
  const {
    refresh,
    fetchNextPage,
    handleChangeHtml,
    createdPost,
    deletePost,
    handleMouseEnter,
    handleMouseLeave,
    handleSelectReaction,
    handleInViewChange,
    isToggleReaction,
    list,
    isError,
    hasNextPage,
    isLoading,
    html,
    modules,
    isErrorCreatePost,
    isLoadingCreatePost,
    isLoadingReaction,
    isLoadingDelete,
    isLoadingModerator,
    isHtmlEmpty,
    matches,
    matches320,
    t,
    emailLogged,
    isModerator,
    postId
  } = usePost()

  return (
    <S.ContainerPosts
      container
      flexDirection='column'
      alignItems='center'
      paddingBottom={2}
    >
      <CreatePost
        html={html}
        handleChangeHtml={handleChangeHtml}
        // @ts-ignore
        modules={modules}
        isLoading={isLoading}
        isErrorCreatePost={isErrorCreatePost}
        isLoadingCreatePost={isLoadingCreatePost}
        createPost={createdPost}
        isHtmlEmpty={isHtmlEmpty}
        t={t}
      />
      <ListPost
        isError={isError}
        isLoading={isLoading || isLoadingModerator}
        data={list}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        refresh={refresh}
        t={t}
        isToggleReaction={isToggleReaction}
        handleMouseEnter={handleMouseEnter}
        isLoadingReaction={isLoadingReaction}
        isLoadingDelete={isLoadingDelete}
        deletePost={deletePost}
        handleMouseLeave={handleMouseLeave}
        handleSelectReaction={handleSelectReaction}
        emailLogged={emailLogged}
        isModerator={isModerator}
        matches={matches}
        handleInViewChange={handleInViewChange}
        matches320={matches320}
        postId={postId}
      />
    </S.ContainerPosts>
  )
}

export default Posts
