import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const useNotFound = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('PAGES', { keyPrefix: 'NOT_FOUND' })

  const handleBack = () => navigate('/')

  return {
    handleBack,
    t
  }
}
