import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Button, Grid, Link, Typography } from '@mui/material'
import { TFunction } from 'i18next'

import PostItem from '../../../../../components/PostItem/PostItem'
import Skeleton from '../../../../../components/Skeleton'
import { useAuthCredentials } from '../../../../../hooks/useAuthCredentials'
import * as S from './styles'
import { ListPostProps } from './types'

const renderError = (t: TFunction, refresh: () => void) => {
  return (
    <>
      <Typography>
        {t('LIST_POST.UNABLE_TO_LOAD_FEED')} 😢
      </Typography><S.ContainerButton>
        <Button variant="contained" color="primary" onClick={refresh} fullWidth>
          {t('LIST_POST.RECHARGE')}
        </Button>
      </S.ContainerButton></>
  )
}

const renderEmpty = (t: TFunction) => (
  <Typography>
    {t('LIST_POST.LIST_EMPTY')}
  </Typography>
)

const renderLoadingSkeleton = () => (
  <S.ContainerLoading>
    {Array(4).fill(null).map((_, index) => <Skeleton key={index} />)}
  </S.ContainerLoading>
)

const ListPost: React.FC<ListPostProps> = ({
  fetchNextPage,
  refresh,
  handleSelectReaction,
  handleMouseEnter,
  handleMouseLeave,
  handleInViewChange,
  deletePost,
  isLoadingDelete,
  isLoadingReaction,
  isLoading,
  isError,
  data,
  hasNextPage,
  t,
  emailLogged,
  isModerator,
  isToggleReaction,
  matches,
  matches320,
  postId
}) => {
  const { authCredentials, isLoading: loadingCredentials } = useAuthCredentials()

  if (!authCredentials || loadingCredentials || isLoading) {
    return renderLoadingSkeleton()
  }

  if (isError) {
    return (
      <Grid display='flex' justifyContent='center' flexDirection='column' height='100vh'>
        {renderError(t, refresh)}
      </Grid>
    )
  }

  return (
    <>
      <Button variant="text" onClick={refresh} >
        {t('LIST_POST.RECHARGE')}
      </Button>
      {data && data.length > 0
        ? <S.ContainerInfiniteScroll>
          <InfiniteScroll
            dataLength={data.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<Link
              variant="body2"
              fontWeight='fontWeightBold'
              color='secondary'
            >
              {t('LIST_POST.LOADING')}
            </Link>}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            {
              data.map(item => (
                <PostItem
                  item={item}
                  key={item.id}
                  handleSelectReaction={handleSelectReaction}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleInViewChange={handleInViewChange}
                  deletePost={deletePost}
                  isLoadingReaction={isLoadingReaction}
                  isLoadingDelete={isLoadingDelete}
                  isToggleReaction={isToggleReaction}
                  emailLogged={emailLogged}
                  isModerator={isModerator}
                  matches={matches}
                  matches320={matches320}
                  t={t}
                  postId={postId}
                />
              ))
            }
          </InfiniteScroll>
        </S.ContainerInfiniteScroll>
        : <Grid display='flex' justifyContent='center' flexDirection='column' height='100vh'>
          {renderEmpty(t)}
        </Grid>
      }
    </>
  )
}

export default ListPost
