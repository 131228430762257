import { useContext } from 'react'

import { AuthCredentialsContext } from '../contexts/Auth'
import { AuthCredentialsService } from '../contexts/Auth/types'

export const useAuthCredentials = (): AuthCredentialsService => {
  const context = useContext(AuthCredentialsContext)
  if (!context) {
    throw new Error(
      'AuthCredentials should be used within a AuthCredentialsProvider'
    )
  }

  return context
}
