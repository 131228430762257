// https://stackoverflow.com/a/34890276/9931154
// https://stackoverflow.com/a/61400956/9931154
export const groupBy = <T extends object, U extends keyof T>(
  xs: T[] = [],
  key: U,
  maxGroups: number = Infinity
): { [key: string]: T[] } => {
  const groupedData = xs.reduce((rv: any, x: T) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})

  if (Object.keys(groupedData).length > maxGroups) {
    const slicedGroups = Object.keys(groupedData).slice(0, maxGroups)
    return slicedGroups.reduce((result: any, key: string) => {
      result[key] = groupedData[key]
      return result
    }, {})
  }

  return groupedData
}
