import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  html, body, #root {
     -webkit-font-smoothing: antialiased !important;
     font-family: 'Inter', sans-serif;
     width: 100%;
     height: 100%;
     background-color: #FAFAFA !important;
  }
  button {
    cursor : pointer;
    font-family: 'Inter', sans-serif;
  }
`
