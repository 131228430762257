import { api } from '../../helpers/axios'
import { IUploadFileResponse } from './types'

async function uploadFile (formData: FormData): Promise<IUploadFileResponse> {
  const response = await api.post<IUploadFileResponse>('/oci-attachments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export const uploadService = {
  uploadFile
}
