export const truncatedContent = (value: string, quantifyCharacter: number) => {
  return value.length > quantifyCharacter ? `${value.slice(0, quantifyCharacter)}...` : value
}

export const capitalizeText = (text?: string): string => {
  if (!text) {
    return ''
  }

  const ignoreList = [
    'bi',
    'it',
    'ti',
    'ui',
    'ux',
    'ui/ux',
    'rh',
    'drd',
    'pdi',
    'ipd',
    'nps',
    'dgs',
    'ia',
    'ai',
    'gpt',
    'gptw'
  ]

  const onlyLowerCaseList = ['de', 'da', 'do', 'dos', 'das', 'e']

  const wordArray = text.split(' ')
  const capitalizedArray = wordArray.map((word: string) => {
    const lowerCaseWord = word.toLowerCase()

    if (ignoreList.includes(lowerCaseWord)) {
      return word
    }
    if (onlyLowerCaseList.includes(lowerCaseWord)) {
      return lowerCaseWord
    }
    return (lowerCaseWord[0] || '').toUpperCase() + lowerCaseWord.substring(1)
  })

  return capitalizedArray.join(' ')
}
