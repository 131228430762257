import React, { useCallback } from 'react'

import Delete from '@mui/icons-material/Delete'
import { CardHeader, IconButton } from '@mui/material'

import { dateUtils } from '../../../utils/date'
import Avatar from './Avatar'

type Props = {
  postId: string;
  authorName: string;
  authorAvatar?: string;
  authorEmail: string;
  createdAt: string;
  deletePost: (postId: string, singlePost:boolean) => void;
  isLoadingDelete: boolean;
  postDeletingId: string;
  emailLogged: string | undefined;
  isModerator: boolean;
  isSinglePost: boolean;
};

const PostHeader: React.FC<Props> = ({ authorName, authorEmail, createdAt, deletePost, postId, isLoadingDelete, postDeletingId, emailLogged, isModerator, isSinglePost }) => {
  const handleClickDelete = useCallback(() => {
    deletePost(postId, isSinglePost)
  }, [])

  const renderDeleteButton = () => {
    if (isModerator || authorEmail === emailLogged) {
      return (
        <IconButton disabled={isLoadingDelete && postDeletingId === postId} onClick={handleClickDelete} aria-label="settings">
          <Delete />
        </IconButton>
      )
    }

    return null
  }

  return (
    <CardHeader
      avatar={
        <Avatar authorName={authorName} authorEmail={authorEmail} />
      }
      action={renderDeleteButton()}
      title={authorName}
      subheader={dateUtils.formatRelative(createdAt)}
    />
  )
}

export default PostHeader
