import Cookies from 'universal-cookie'

const maxAge = 315360000 // 10 anos em segundos

const cookies = new Cookies()
const DEFAULT_OPTIONS = { path: '/' }

export default {
  /**
    * Armazena a chave e valor nos cookies.
    */
  setItem (key: string, value: string, opts = { temporary: false }) {
    const options = {
      ...DEFAULT_OPTIONS,
      maxAge: opts.temporary ? undefined : maxAge,
      secure: true,
      ...opts
    }
    return cookies.set(key, value, options)
  },
  /**
    * Recupera o valor da chave armazenado nos cookies.
    */
  getItem (key: string) {
    return cookies.get(key)?.value
  },
  options (key: string) {
    return cookies.get(key)?.options
  },
  /**
    * Remove o valor da chave armazenado nos cookies.
    */
  removeItem (key: string) {
    return cookies.remove(key, DEFAULT_OPTIONS)
  },
  clear () {
    const allCookies = cookies.getAll()
    Object.keys(allCookies).forEach(ck => cookies.remove(ck, DEFAULT_OPTIONS))
    return allCookies
  }
}
