import React, { useEffect, useState } from 'react'

import { Avatar as MuiAvatar, Skeleton } from '@mui/material'
import { useMutation } from '@tanstack/react-query'

import AvatarPlaceholder from '../../../assets/avatar.png'
import { postService } from '../../../services/PostService'

type AvatarPropsType = {
  authorName?: string;
  authorEmail?: string;
}

const WIDTH = 40
const HEIGHT = 40

const Avatar = ({ authorName, authorEmail }: AvatarPropsType) => {
  const [photo, setPhoto] = useState<string| undefined>()
  const alt = authorName || 'autor'

  const { mutate: mutateGetAuthorAvatar, isLoading: isLoadingAuthorAvatar } = useMutation(postService.getAuthorAvatar, {
    onSuccess: (result) => {
      const objectUrl = URL.createObjectURL(result)
      setPhoto(objectUrl)
    },
    onError: () => {
      setPhoto(undefined)
    }
  })

  useEffect(() => {
    if (authorEmail) {
      mutateGetAuthorAvatar(authorEmail)
    }
  }, [])

  if (isLoadingAuthorAvatar) {
    return (
      <Skeleton variant="circular" style={{ minWidth: `${WIDTH}px`, minHeight: `${HEIGHT}px` }} width={WIDTH} height={HEIGHT} />
    )
  }

  if (!photo || !authorEmail) {
    return (
      <img src={AvatarPlaceholder} width={WIDTH} height={HEIGHT} />
    )
  }

  return (
    <MuiAvatar alt={alt} src={photo} />
  )
}
export default Avatar
