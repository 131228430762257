import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from 'axios'
import { t } from 'i18next'

import ToastNotification from '../components/ToastNotification'
import appConfig from '../config/app'
import { AuthTokenCredentials } from '../contexts/Auth/types'

export const api = axios.create({
  baseURL: appConfig.apiBaseUrl
})

const HttpStatusError:Record<number, string> = {
  500: t('HTTP_ERROR:STATUS_500'),
  422: t('HTTP_ERROR:STATUS_422'),
  412: t('HTTP_ERROR:STATUS_412'),
  404: t('HTTP_ERROR:STATUS_404'),
  403: t('HTTP_ERROR:STATUS_403'),
  401: t('HTTP_ERROR:STATUS_401')
}

type InterceptorProps = {
  authCredentials: AuthTokenCredentials | null;
};

export const registerInterceptor = ({
  authCredentials
}: InterceptorProps) => {
  const request = async (config: InternalAxiosRequestConfig) => {
    if (config && config.headers && authCredentials) {
      config.headers.Authorization = `Bearer ${authCredentials?.token}`
    }
    return config
  }

  api.interceptors.request.use(request, function (error) {
    return Promise.reject(error)
  })

  const successResponse = async (response: AxiosResponse) => {
    return response
  }

  const failResponse = async (errorResponse: AxiosResponseHeaders) => {
    const errorObj = errorResponse.response.config.method === 'delete' ? t('HTTP_ERROR:ERROR_EXCLUDE_POST') : HttpStatusError[errorResponse.response.status]

    if (!errorResponse.response.config.headers.disabledToast) {
      ToastNotification({
        id: `Error-${errorResponse.response.status}`,
        className: 'toast-error',
        content: errorObj || errorResponse.response.data.error.message
      })
    }

    return Promise.reject(errorResponse)
  }

  api.interceptors.response.use(successResponse, failResponse)
}
