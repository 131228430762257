import React, { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Link } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'

import { QueryKeys } from '../../../helpers/queryKeys'
import { postService } from '../../../services/PostService'
import { IComment } from '../../../services/PostService/types'
import * as S from '../styles'
import CommentAnswersSkeleton from './CommentAnswersSkeleton'
import InputComment from './InputComment'
import PostCommentsItem from './PostCommentsItem'

type PostCommentsPropsType = {
  t: TFunction
  postId: string;
  showAllAnswers: boolean;
}

const PostComments = ({ t, postId, showAllAnswers }: PostCommentsPropsType) => {
  const query = useInfiniteQuery(
    [QueryKeys.Comments, postId],
    {
      queryFn: ({ pageParam = 1 }) => postService.getComments({
        postId,
        page: pageParam,
        limit: 30,
        orders: Array(JSON.stringify(['created_at', 'asc']))
      }),
      getNextPageParam: ({ metadata }) => {
        const nextPage = metadata.page + 1
        return nextPage <= metadata.pages ? nextPage : undefined
      },
      refetchOnWindowFocus: false,
      retry: 0
    }
  )

  const items = useMemo(() => {
    return query.data
      ? query.data.pages.reduce<IComment[]>((prev, curr) => {
        return [...prev, ...curr.result]
      }, [])
      : []
  }, [query.data])

  const refreshList = () => {
    return query.refetch()
  }

  const renderContent = () => {
    if (query.isError) {
      return <div style={{ margin: 16, textAlign: 'center' }}>{t('VIEW.FAIL_FIND_COMMENTS')}</div>
    }

    if (query.isLoading) {
      return (<CommentAnswersSkeleton />)
    }

    if (!items.length) {
      return <div style={{ display: 'flex', flex: 1 }} />
    }

    return (
      <S.ContainerComments>
        <InfiniteScroll
          dataLength={items.length}
          next={query.fetchNextPage}
          hasMore={Boolean(query.hasNextPage)}
          style={{ display: 'flex', flexDirection: 'column' }}
          loader={<Link
            variant="body2"
            fontWeight='fontWeightBold'
            color='secondary'
          >
            {t('VIEW.LOADING')}
          </Link>}
        >
          {items.map(comment => {
            return (
              <PostCommentsItem
                t={t}
                key={comment.id}
                comment={comment}
                showAllAnswers={showAllAnswers}
              />
            )
          })}
        </InfiniteScroll>
      </S.ContainerComments>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingBottom: 64 }}>
      {renderContent()}
      <InputComment postId={postId} onSuccess={refreshList} t={t} />
    </div>
  )
}

export default PostComments
