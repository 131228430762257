import React, { useState } from 'react'

import { Button, Link, Typography } from '@mui/material'
import { TFunction } from 'i18next'

import { IComment } from '../../../services/PostService/types'
import { dateUtils } from '../../../utils/date'
import { capitalizeText, truncatedContent } from '../../../utils/global'
import Avatar from './Avatar'
import PostCommentsAnswers from './PostCommentsAnswers'

type PostCommentsItemPropsType = {
  t: TFunction
  comment: IComment;
  showAllAnswers: boolean;
}

const CHARACTER_LENGTH = 100

const PostCommentsItem = ({ t, comment, showAllAnswers }: PostCommentsItemPropsType) => {
  const [isExpandedContent, setIsExpandedContent] = useState(true)
  const [showInputAnswer, setShowInputAnswer] = useState(false)

  const handleClickExpand = () => {
    setIsExpandedContent(oldState => !oldState)
  }

  const handleClickAnswer = () => {
    setShowInputAnswer(oldState => !oldState)
  }

  const handleCreateAnswer = () => {
    setShowInputAnswer(false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '8px 0px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        <Avatar authorName={capitalizeText(comment.user_name)} authorEmail={comment.user_email} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ backgroundColor: '#f0f2f5', padding: 12, borderRadius: 9 }}>
            <div style={{ marginBottom: 8 }}>
              <Typography variant="body2">{capitalizeText(comment.user_name)}</Typography>
              <Typography variant="body2" style={{ color: 'rgb(0 0 0 / 60%)' }}>{dateUtils.formatRelative(comment.created_at)}</Typography>
            </div>
            <div className="ql-editor">
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                <p style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'anywhere' }}>
                  <div dangerouslySetInnerHTML={{ __html: isExpandedContent ? comment.comment : truncatedContent(comment.comment, CHARACTER_LENGTH) }} />
                  {comment.comment.length > CHARACTER_LENGTH && (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={handleClickExpand}
                    >
                      {isExpandedContent ? t('COMMENT_ITEM.SEE_LESS') : t('COMMENT_ITEM.SEE_MORE')}
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant="text"
              onClick={handleClickAnswer}
            >
              {showInputAnswer ? t('COMMENT_ANSWER.CANCEL') : t('COMMENT_ANSWER.ANSWER')}
            </Button>
          </div>
        </div>
      </div>
      <PostCommentsAnswers
        t={t}
        commentId={comment.id}
        commentAuthor={comment.user_name}
        showInput={showInputAnswer}
        showAllAnswers={showAllAnswers}
        onSuccess={handleCreateAnswer}
      />
    </div>
  )
}

export default PostCommentsItem
