import { Grid } from '@mui/material'
import styled from 'styled-components'

export const ContainerAuthIFrame = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 6,
  sm: 8
})`
  width: 100%;

  /* @media screen and (max-width: 600px) {
    padding: 16px;
  } */
`
