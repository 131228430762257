import { AuthTokenCredentials } from '../../contexts/Auth/types'
import { api } from '../../helpers/axios'
import { storage } from '../../utils/storage'
import { ModeratorResponse } from './types'

const AUTH_KEY = '@Auth'

function setLocalStorage (ac: AuthTokenCredentials): void {
  storage.setItem(AUTH_KEY, ac)
}

function getLocalStorage (): Promise<AuthTokenCredentials | null> {
  const ac = storage.getItem<AuthTokenCredentials>(AUTH_KEY)
  return ac
}

function removeLocalStorage (): void {
  storage.removeItem(AUTH_KEY)
}

function updateToken (token: string) {
  api.defaults.headers.common.Authorization = token
}

function removeToken () {
  api.defaults.headers.common.Authorization = null
}

async function getModeratorPermission (): Promise<ModeratorResponse> {
  const response = await api.get('/users-moderators/moderator')
  return response.data || false
}

async function getToken (): Promise<string | null> {
  const response = await storage.getItem<string | null>('token')
  return response
}

export const authService = {
  updateToken,
  removeToken,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  getModeratorPermission,
  getToken
}
